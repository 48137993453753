// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
import './filter';
import './menuHandler';
import './coworker';
import './slider';
import './work-filter';
import './chatWidget';

window.$ = require('jquery');
window.jQuery = window.$;

var midnight = require('midnight.js');

$(function() {
	$('.midnight-container').midnight();

	$('#submitMessage').on('click', function() {
		const _this = this;
		_this.disabled = 'disabled';
		_this.value = 'Sending...';
		$.ajax({
			type: 'post',
			url: window.ajaxUrl,
			data: {
				action: 'submit_email',
				message: $('#userMessage').val(),
				user_email: $('#userEmail').val(),
				author_id: $(_this).data('author-id'),
			},
			success: function(response)
			{
				if (response.success) {
					console.log("success");
					$('#thank-you-chat').fadeIn(200, function() {
						$('#userMessage').val('');
						$('#userEmail').val('');
						setTimeout(function(){ $('#thank-you-chat').fadeOut(350); }, 1500);
						$('#submitMessage').prop('disabled', false);
						$('#submitMessage').val('Send');
						setTimeout(function(){ $('#js-chat-window').removeClass('visible'); }, 3000);
					});
				} else {
					console.log("error", response);
				}
			}
		});
	})
});